import * as THREE from "three";
import React from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
//import { FirstPersonControls } from "three/addons/controls/FirstPersonControls.js";

import { useEffect, useRef } from "react";

function Wallpaper() {
  const refContainer = useRef(null);

  useEffect(() => {
    // Scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#fff");

    // fog
    const color = 0xffffff;
    const density = 0.0036;
    scene.fog = new THREE.FogExp2(color, density);

    const camera = new THREE.PerspectiveCamera(14, window.innerWidth / window.innerHeight);

    camera.position.z = 120; // 108.29282453443182;
    camera.position.x = 120; // 176.04330146998313; //+ 15;

    camera.position.y = -45; //88.84861055868186;

    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    // document.body.appendChild( renderer.domElement );
    // use ref as a mount point of the Three.js scene instead of the document.body
    refContainer.current && refContainer.current.appendChild(renderer.domElement);

    // Controller

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = false;

    var torus = new THREE.Scene();
    torus.rotation.z = Math.PI;

    scene.add(torus);

    const p_geometry = new THREE.SphereGeometry(0.08, 16, 16);

    //var materials = [];

    var p = 0;

    const a = 3;
    const b = 1;
    const k = 0.001;

    const scaleFactor = 10; // Adjust this for better visualization

    for (let u = 0; u <= 1000 * 2 * Math.PI; u += 1) {
      var p_material = new THREE.MeshBasicMaterial();

      p_material.color.set(new THREE.Color("#000"));

      const v = k * u;

      const x = (a + b * Math.cos(u)) * Math.cos(v) * scaleFactor;
      const y = (a + b * Math.cos(u)) * Math.sin(v) * scaleFactor;
      const z = b * Math.sin(u) * scaleFactor;

      var particle = new THREE.Mesh(p_geometry, p_material);

      particle.dx = Math.random() * 10 - 5;
      particle.dy = Math.random() * 10 - 5;
      particle.dz = Math.random() * 10 - 5;
      particle.theta = Math.random() * 2 * Math.PI * 0.04;
      particle.s = 2;

      particle.position.x = x + particle.s * particle.dx * Math.sin(particle.theta);
      particle.position.y = y + particle.s * particle.dy * Math.sin(particle.theta);
      particle.position.z = z + particle.s * particle.dz * Math.sin(particle.theta);

      particle.ox = x;
      particle.oy = y;
      particle.oz = z;

      torus.add(particle);
      p++;
    }

    function anim(scenex) {
      for (p = 0; p < scenex.children.length; p++) {
        var particle = scenex.children[p];
        particle.position.x = particle.ox + particle.s * particle.dx * Math.sin(particle.theta);
        particle.position.y = particle.oy + particle.s * particle.dy * Math.sin(particle.theta);
        particle.position.z = particle.oz + particle.s * particle.dz * Math.sin(particle.theta);
        particle.theta += 0.004;
      }
    }

    function animate() {
      torus.rotation.z -= 0.0016;

      anim(torus);
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    }

    animate();

    window.addEventListener("resize", onWindowResize, false);

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    }
  }, []);
  return <div ref={refContainer} className="fixed h-screen w-screen m-0 p-0"></div>;
}

export default Wallpaper;
