import Wallpaper from "./wallpaper.js";
import Accordion from "./Accordion.jsx";
import EmblaCarousel from "./EmblaCarousel";

//
import "./App.css";

function App() {
  const slidesPlotter = [
    {
      img: "img/materialise-9x12x12.jpg",
      alt: "Materialise - Generative edition of 20 - pigment ink on acid free paper 297x420mm",
    },
    {
      img: "img/lake-burragorang.jpg",
      alt: "Lake Burragorang - Edition of 10 - acrylic ink on acid free paper 297x420mm",
    },
    {
      img: "img/mare-orientale.jpg",
      alt: "Mare Orientale - Edition of 10 - pigment ink on acid free paper 297x420mm",
    },
    {
      img: "img/beckett.jpg",
      alt: "Samuel Beckett (After John Haynes) - Edition of 10 - acrylic ink on acid free paper 297x420mm",
    },
  ];

  const slidesFluidity = [
    { img: "img/fluidity-2.jpg", alt: "Fluidity - Screen print on canvas. 2m x 2m" },
    { img: "img/fluidity-1.jpg", alt: "In the screen printing studio" },
    { img: "img/fluidity-3.jpg", alt: "Fluidity - Screen print on canvas" },
    { img: "img/fluidity-4.jpg", alt: "Fluidity - Screen print on canvas" },
  ];

  const slidesTGOFP = [
    {
      img: "img/tgofp-the-path.jpg",
      alt: "The Path, by Tale of Tales - Auriea Harvey and Michael Samyn",
    },
    { img: "img/tgofp-moondust.jpg", alt: "Moondust - Jaron Lanier" },
    {
      img: "img/tgofp-puppet-motel.jpg",
      alt: "Puppet Motel - Laurie Anderson with Hsin-Chien Huang",
    },
    { img: "img/tgofp-island.jpg", alt: "Island - Nina Pope and Karen Guthrie" },
    { img: "img/tgofp-character-mirror.jpg", alt: "Character Mirror - David O'Reilly" },
    { img: "img/tgofp-frame-game.jpg", alt: "Frame Game - Michael Nyman" },
    { img: "img/tgofp-cutexdoom.jpg", alt: "Cute X Doom - Anita Fontaine and Mike Pelletier" },
  ];

  const slidesOptions = { loop: true };

  const data = [
    {
      id: 0,
      label: "About",
      renderContent: () => (
        <>
          <div className="w-full flex flex-col md:flex-row mx-auto max-w-full">
            <div className="w-full md:w-5/12 mb-8 md:mb-0">
              <img
                src="img/neil-courier-mail.jpg"
                alt="Neil Jenkins, pictured at Bundaburg Regional Art Gallery"
              />
            </div>
            <div className="w-full md:w-7/12 lg:w-6/12 xl:w-6/12 md:px-6">
              <div className="">
                <div className="w-full">
                  <p className="text-xl mb-4">
                    I am an interdisciplinary artist based in Sydney, Australia, working across a
                    broad spectrum of digital and physical mediums. My practice explores the
                    intersection of data and the digital form, and their transformation into visual
                    and physical spaces.
                  </p>
                  <p className="text-xl mb-4">
                    With an extensive background in design, photography and computer programming,
                    I’ve developed projects across a range of platforms &ndash; both low- and
                    high-tech &ndash; creating generative systems, interactive multi-user web
                    environments, and digital installations.
                  </p>
                  <p className="text-xl mb-4">
                    In recent years, I’ve focused on the integration of traditional printmaking
                    techniques with technology, working with a computer-controlled pen plotter and
                    screen printing as a means to further explore how the digital can be embodied in
                    the physical world.
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      id: 1,
      label: "Generative and procedural drawings",
      renderContent: () => (
        <div className="w-full flex flex-col md:flex-row mx-auto max-w-full">
          <div className="w-full md:w-5/12 mb-8 md:mb-0">
            <EmblaCarousel slides={slidesPlotter} options={slidesOptions} />
          </div>
          <div className="w-full md:w-7/12 lg:w-6/12 xl:w-6/12 md:px-6">
            <div className="">
              <div className="w-full">
                <p className="text-xl mb-4">
                  Generative and computational ideas developed in code and drawn by a computer
                  controlled pen plotter.
                </p>
                <p className="text-xl mb-4">
                  Derived from procedural algorithms, parametric equations, digital elevation maps
                  and other data sets, these works forge a connection between drawing and the
                  virtual &ndash; translating/mapping data into a physical form.
                </p>
                <p className="text-xl mb-8">
                  All works are drawn on acid free paper with pigment and acrylic inks. Some are
                  available for purchase in the{" "}
                  <a
                    href="https://computationalau.square.site/"
                    target="new"
                    title="Computational shop"
                  >
                    Computational shop
                  </a>
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 2,
      label: "Fluidity",
      renderContent: () => (
        <div className="w-full flex flex-col md:flex-row mx-auto max-w-full">
          <div className="w-full md:w-5/12 mb-8 md:mb-0">
            <EmblaCarousel slides={slidesFluidity} options={slidesOptions} />
          </div>
          <div className="w-full md:w-7/12 lg:w-6/12 xl:w-6/12 md:px-6">
            <div className="">
              <div className="w-full">
                <p className="text-xl mb-4">
                  <span className="font-bold">Fluidity</span>
                  <br />
                  Screen print on canvas. 2m x 2m.
                </p>
                <p className="text-xl mb-4">
                  Created for the exhibition{" "}
                  <span className="font-bold">A Thousand Beautiful Things</span> curated by{" "}
                  <a href="https://lizbradshaw.net/" target="new">
                    Liz Bradshaw
                  </a>
                  &nbsp;bringing together an amazing group of queer and diverse artists whose work
                  explores everyday symbolism, aesthetics, and queer points of view.
                </p>
                <p className="text-xl mb-8">
                  Work by Ali Tahayori, Armando Chant, Justine Youssef, Christine Dean, Blake
                  Griffiths, Nadia Odlum, Neil Jenkins and Kurt Banks.
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 3,
      label: "Horizons",
      renderContent: () => (
        <div className="w-full flex flex-col md:flex-row mx-auto max-w-full">
          <div className="w-full md:w-5/12 mb-8 md:mb-0">
            <div className="relative p-l-[56.25%] h-[100%] min-h-[270px]">
              <iframe
                src="https://player.vimeo.com/video/897829030?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                title="Horizons"
                data-ready="true"
              ></iframe>
            </div>
          </div>
          <div className="w-full md:w-7/12 lg:w-6/12 xl:w-6/12 md:px-6">
            <div className="">
              <div className="w-full">
                <p className="text-xl mb-4">
                  <span className="font-bold">Horizons</span>
                  <br />
                  HD video, 11’ 24” photographs, custom generative software.
                </p>
                <p className="text-xl mb-8 md:mb-24">
                  Drawing on a series of photographic images from dawn till dusk, Horizons is a
                  constantly morphing colour field, centred on the horizon, that appears to race
                  through the landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 4,
      label: "A Wrinkle in Time",
      renderContent: () => (
        <div className="w-full flex flex-col md:flex-row mx-auto max-w-full">
          <div className="w-full md:w-5/12 mb-8 md:mb-0">
            <img src="img/awit.jpg" alt="A Wrinkle in Time" />
          </div>
          <div className="w-full md:w-7/12 lg:w-6/12 xl:w-6/12 md:px-6">
            <div className="">
              <div className="w-full">
                <p className="text-xl mb-4">
                  <span className="font-bold">A Wrinkle in Time</span>
                  <br />
                  Custom software
                </p>
                <p className="text-xl mb-4">
                  Based on the book “A Wrinkle in Time” by Madelaine L’Engle, this piece continues
                  my exploration of the surrealist exquisite corpse text cut-up, taking an existing
                  piece of literature and rewriting it word by word – every word is followed by one
                  that might appear after it in the original text.{" "}
                </p>
                <p className="text-xl mb-4">
                  “In this book, the characters travel by ‘wrinkling time’ through a kind of
                  wormhole referred to as a tesseract” &ndash; Madelaine L’Engle
                </p>
                <p className="text-xl mb-4">
                  In this piece, you travel through the text, jumping between the original text of
                  the story and cut up versions by clicking on the words.{" "}
                </p>
                <p className="text-xl mb-8">
                  Commissioned by Bundaberg Regional Art Gallery for Literary Notions, a series of
                  installations by artists using literature as their inspiration.
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 5,
      label: "The Garden of Forking Paths",
      renderContent: () => (
        <div className="w-full flex flex-col md:flex-row mx-auto max-w-full">
          <div className="w-full md:w-5/12 mb-8 md:mb-0">
            <EmblaCarousel slides={slidesTGOFP} options={slidesOptions} />
          </div>
          <div className="w-full md:w-7/12 lg:w-6/12 xl:w-6/12 md:px-6">
            <div className="">
              <div className="w-full">
                <p className="text-xl font-bold">The Garden of Forking Paths</p>
                <p className="text-xl mb-4">Curated by Neil Jenkins, toured by D/Lux Media Arts</p>

                <p className="text-xl mb-4">
                  Jorge Luis Borges' 1941 short story "The Garden of Forking Paths" predates the
                  Internet but its notions of non-linearity, the storyline surrounding an infinite,
                  labyrinthine book that realises multiple paths and futures are echoed in the
                  information age with hypertext, the World Wide Web and the form and structure of
                  computer games.
                </p>
                <p className="text-xl mb-4">
                  Just as Borges and his contemporaries pushed the envelope of the narrative form,
                  so too artists have been creating and modifying computer games, experimenting with
                  the notions of what a game is and exploring alternate approaches to interaction
                  and play methodologies. This exhibition draws together notable historic and
                  contemporary examples of games created by artists that push the bounds of the
                  genre and break the orthodox set of rules.
                </p>
                <p className="text-xl mb-4">
                  Featuring work spanning from the early 1980s to the 2010s, played on contemporary
                  hardware of the time from the Commodore64 to the Oculus VR headset.
                </p>
                <p className="text-xl mb-4">
                  Many thanks to the artists &ndash; Laurie Anderson (USA) and Hsin-Chien Huang
                  (Taiwan), Jaron Lanier (USA), Andy Deck (USA), Michael Nyman (UK), Nina Pope and
                  Karen Guthrie (UK), Jakub Dvorsky (Czech Republic), Anita Fontaine (Australia) and
                  Mike Pelletier (Canada), Tale of Tales (Belgium), Guillaume Reymond (Switzerland),
                  Andrew Burrell and Chris Rodley (Australia), David O'Reilly (UK).
                </p>
                <p className="text-xl mb-8">
                  The exhibition is now retired but toured regional galleries around Australia for 5
                  years.
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 6,
      label: "Guernica [cover-up]",
      renderContent: () => (
        <div className="w-full flex flex-col md:flex-row mx-auto max-w-full">
          <div className="w-full md:w-5/12 mb-8 md:mb-0">
            <img src="img/guernica[cover-up].png" alt="Guernica [cover-up]" />
          </div>
          <div className="w-full md:w-7/12 lg:w-6/12 xl:w-6/12 md:px-6">
            <div className="">
              <div className="w-full">
                <p className="text-xl font-bold mb-2">Guernica [cover-up]</p>
                <p className="text-xl mb-4">
                  Digital image. 2003.{" "}
                  <a
                    href="https://netpraxis.net/guernica[cover-up]/guernica[cover-up].gif"
                    target="new"
                  >
                    View full image
                  </a>
                  <br />
                  Digital print. 2003. 2m x 1m. Private collection.
                </p>
                <p className="text-xl mb-4">
                  "Earlier this week, U.N. officials hung a blue curtain over a tapestry
                  reproduction of Picasso’s Guernica at the entrance of the Security Council. The
                  spot is where diplomats and others make statements to the press, and ostensibly
                  officials thought it would be inappropriate for Colin Powell to speak about war in
                  Iraq with the 20th century’s most iconic protest against the inhumanity of war as
                  his backdrop."
                </p>
                <p className="text-xl mb-8">
                  David Cohen,{" "}
                  <a
                    href="https://slate.com/news-and-politics/2003/02/what-s-behind-the-u-n-cover-up-of-picasso-s-guernica.html"
                    target="new"
                  >
                    Slate 06/02/2003
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 7,
      label: "Exhibitions and publications",
      renderContent: () => (
        <div className="w-full flex flex-col py-8 mx-auto max-w-full p-2">
          <div className="">
            <h3 className="mb-6 text-xl">Solo Exhibitions</h3>
            <ul className="cv mb-6 text-xl">
              <li>
                2014 <em>A Wrinkle in Time</em> Literary Notions Bundaberg Regional Arts Gallery,
                Bundaberg, QLD
              </li>
            </ul>
            <h3 className="mb-6 text-xl">Group Exhibitions</h3>
            <ul className="cv mb-6 text-xl">
              <li>
                2023 <em>A Thousand Beautiful Things</em> Brookfield Place, Sydney, NSW
              </li>
              <li>
                2014 <em>Horizons</em> Brenda May Gallery, Sydney, NSW
              </li>
              <li>
                2013 <em>Urban Screen</em> Chatswood, Sydney, NSW
              </li>

              <li>
                2013 <em>dTV</em> Federation Square, Melbourne, VIC
              </li>
              <li>
                2012 <em>Art After Dark</em> Biennale of Sydney Pier 2/3 Walsh Bay, Sydney, NSW
              </li>
              <li>
                2011 <em>For Arts Sake</em> Sydney College of the Arts, Rozelle, Sydney
              </li>
              <li>
                2010 <em>Memory Flows </em>The Armory, Sydney Olympic Park, NSW
              </li>
              <li>
                2010 <em>Time Travel: Reimagining the Past</em> Tweed River Art Gallery,
                Murwillumbah NSW
              </li>
              <li>
                2009 <em>DIWO</em> at the Dark Mountain HTTP Gallery, London, UK
              </li>
              <li>
                2009 <em>Intimate</em> Loop Space, Newcastle, NSW
              </li>
              <li>
                2008 <em>Without Boundaries</em> Art Gallery of New South Wales Research Library,
                Sydney, NSW
              </li>
              <li>
                2006 <em>Soundtoys</em> Watershed Media Centre, Bristol, UK
              </li>
              <li>
                2006 <em>Screenshot(s)</em> Shrewsbury Museum &amp; Art Gallery, UK
              </li>
              <li>
                2005-2006 <em>Net:Reality</em> 20:21 Visual Arts Gallery, Scunthorpe. Shrewsbury
                Museum &amp; Art Gallery. Q-Arts, Derby. Space4, Peterborough, UK
              </li>
              <li>
                2005 <em>Third Iteration</em> Centre for Electronic Media Art, Monash University,
                Melbourne, VIC
              </li>
              <li>
                2004 <em>Dissension Convention</em> Postmasters Gallery, New York, USA
              </li>
              <li>
                2003 <em>12:12</em> Cardiff School of Art and Design, Cardiff, UK
              </li>
              <li>
                2003 <em>Skin/Strip</em> Site Gallery, Sheffield, UK
              </li>
              <li>
                2003 <em>where r u ?</em> Group event, performance &amp; internet project. Bristol,
                UK
              </li>
              <li>
                2002 <em>Mute-Dialogue</em> Watershed Media Centre, Bristol, UK
              </li>
              <li>
                2002 <em>...here nor there... </em>Kibla Multimedia Center, Maribor, Slovenia
                <br />
              </li>
              <li>
                2000 <em>Medi@terra</em> Athens, Greece
              </li>
            </ul>
            <h3 className="mb-6 text-xl">Online projects</h3>
            <ul className="cv mb-6 text-xl">
              <li>
                2004-2010 <em>VisitorsStudio</em> Live multi user A/V mixing environment -
                <a
                  href="http://web.archive.org/web/20210411015653/http://www.visitorsstudio.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  visitorsstudio.org
                </a>
              </li>
              <li>
                2003 <em>FurtherStudio</em> virtual artist in residence environment with
                Furtherfield.org -
                <a href="http://web.archive.org/web/20210411015653/http://www.furtherfield.org/furtherstudio/docs/about.html/">
                  furtherfield.org/furtherstudio
                </a>
              </li>
              <li>
                2003 <em>Skin/Strip Online</em> interactive online installation with
                <a
                  href="http://web.archive.org/web/20210411015653/http://furtherfield.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Furtherfield.org
                </a>
                and
                <a
                  href="http://web.archive.org/web/20210411015653/http://www.completelynaked.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Completely Naked
                </a>
                - <del>www.skinstrip.net</del>
              </li>
              <li>
                2002 <em>Informer</em> Part of Mute-Dialogue a multimedia projected initiated by
                Yasser Rashid -
                <a href="http://web.archive.org/web/20210411015653/http://www.mute-dialogue.com/">
                  www.mute-dialogue.com
                </a>
              </li>
              <li>
                1999-2003 <em>..here nor there..</em> International Artists Collaboration -
                <a href="http://web.archive.org/web/20210411015653/http://www.herenorthere.net/">
                  www.herenorthere.net
                </a>
              </li>
            </ul>
            <h3 className="mb-6 text-xl">Selected Performances</h3>
            <ul className="cv mb-6 text-xl">
              <li>
                2006 <em>Month of Sundays</em> Watershed Media Centre, Bristol, UK
              </li>
              <li>
                2004 <em>Dissension Convention</em> online performance curated by
                <a
                  href="http://web.archive.org/web/20210411015653/http://furtherfield.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Furtherfield.org
                </a>
                <a
                  href="http://web.archive.org/web/20210411015653/http://www.furtherfield.org/dissensionconvention/"
                  target="_blank"
                  rel="noreferrer"
                >
                  furtherfield.org/dissensionconvention
                </a>
              </li>
              <li>
                2001 <em>Walls Have Ears</em> Audio visual performance with Roger Mills Various
                Venues, UK
              </li>
            </ul>
            <h3 className="mb-6 text-xl">Commissions &amp; Residencies</h3>
            <ul className="cv mb-6 text-xl">
              <li>
                2014 <em>A Wrinkle in Time</em> Bundaberg Regional Arts Gallery, Bundaberg, QLD
              </li>
              <li>
                2000 <em>Web artist in residence</em> for Year of the Artist, South West Arts, UK
              </li>
              <li>
                1997 <em>Web artist in residence</em> DA2 - Digital Arts Development Agency,
                Bristol, UK
              </li>
            </ul>
            <h3 className="mb-6 text-xl">Curation</h3>
            <ul className="cv mb-6 text-xl">
              <li>
                2009 <em>The Garden of Forking Paths</em> - Artists' Computer Games. Western Plains
                Cultural Centre, May 2013. Bathurst Regional Gallery, March 2012. Tin Sheds,
                University of Sydney, November 2011. Glasshouse Gallery, Port Macquarie, February
                2011. Burnie Regional Art Gallery, July 2010. Electrofringe, Loop Space
                Newcastle&nbsp; 2&nbsp; – 24 October 2009 &nbsp;
                <a
                  href="http://web.archive.org/web/20140313063743/http://dlux.org.au/tgofp/index.html"
                  target="new"
                >
                  dlux.org.au/tgofp
                </a>
              </li>
              <li>
                2001 <em>12-12</em> Live 12 hour show and webcast, featuring national and
                international performers, sonic and video artists. Cardiff School of Art and Design,
                Cardiff, UK
              </li>
            </ul>
            <h3 className="mb-6 text-xl">Awards</h3>
            <ul className="cv mb-6 text-xl">
              <li>
                2009 <em>Visitors Studio</em> Grand Prize &nbsp;
                <a
                  href="http://web.archive.org/web/20210411015653/http://netarts.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Netarts.org
                </a>
              </li>
            </ul>
            <h3 className="mb-6 text-xl">Professional Associations</h3>
            <ul className="cv mb-6 text-xl">
              <li>
                2013 <em>Juror</em> &nbsp;
                <a
                  href="http://web.archive.org/web/20210411015653/http://www.australiacouncil.gov.au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Australia Council for the Arts
                </a>
                (Interarts, Digital Programs)
              </li>
              <li>
                2009-2011 <em>Chair/Board member</em> &nbsp;
                <a href="http://web.archive.org/web/20210411015653/http://dlux.org.au/">
                  dLux Media Arts
                </a>
                , Sydney, NSW
              </li>
              <li>
                2000-2008 <em>Technical Director for projects</em>&nbsp;
                <a href="http://web.archive.org/web/20210411015653/http://furtherfield.org/">
                  Furtherfield.org
                </a>
              </li>
              <li>
                2000-2001 <em>Contributor</em>&nbsp;
                <a
                  href="http://web.archive.org/web/20210411015653/http://netartreview.net/"
                  target="_blank"
                  rel="noreferrer"
                >
                  netartreview.net
                </a>
              </li>
            </ul>
            <h3 className="mb-6 text-xl">Publications</h3>
            <ul className="cv mb-6 text-xl">
              <li>
                2005 <em>Third Iteration</em> Centre for Electronic Media Art, Monash University,
                Melbourne, VIC
              </li>
              <li>
                2003 <em>The Orbital Poetry Engine</em> : LEONARDO (Vol. 36, No. 4) : MIT Press
              </li>
              <li>
                2003 <em>The Art of War </em>Decode Magazine (Issue 7) UK
              </li>
              <li>
                2003 <em>Poems That Go</em> (Spring/Summer 2003) Internet
                <a
                  href="http://web.archive.org/web/20210411015653/http://www.poemsthatgo.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.poemsthatgo.com
                </a>
              </li>
              <li>
                2003 <em>low-fi - referencing other spaces</em>&nbsp;
                <del>www.low-fi.org.uk</del>
              </li>
              <li>
                2002 <em>Shaken Identity</em> Decode Magazine (Issue 5) UK
              </li>
              <li>
                2002 <em>The Four Dimensional Monument</em> Fuse Magazine (Volume 25, Number 4)
                Canada
              </li>
              <li>
                2002
                <em> Low-Fi guest list : 'click here to skip intro'</em>
                Internet <del>www.low-fi.org.uk</del>
              </li>
              <li>
                2001
                <em> 365 (Year of the Artist in the South West)</em> Agre Books, UK
              </li>
            </ul>
            <h3 className="mb-6 text-xl">Teaching</h3>
            <ul className="cv mb-6 text-xl">
              <li>
                2003 <em> Researcher, Time Based Art</em> Cardiff School of Art and Design, Cardiff,
                UK
              </li>
              <li>
                1999-2008
                <em> Lecturer, Graphic Design and Interactive Media</em>&nbsp; School of Art and
                Design, Bath Spa University, Bath, UK
              </li>
            </ul>
            <p>&nbsp;</p>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Wallpaper />
      <div className="w-full top-[50vh] z-10 absolute overflow-scroll">
        <div className="flex flex-col w-max-[600px] md:w-max[728px] lg:max-w-[984px] xl:max-w-[1240px] bg-[#fff] bg-opacity-[96%] mx-auto">
          {/* 
          
          title 
          
          */}
          <div className="border-b border-gray-200 bg-opacity-20">
            <div className="flex flex-row text-slate-900">
              <div className="w-full my-1 py-3 px-6">
                <p className="font-bold text-6xl leading-tight -mb-3">COMPUTATIONAL</p>
                <p className="text-2xl leading-tight">Artist and Developer Neil Jenkins</p>
              </div>
            </div>
          </div>
          {/* 
          
          works 
          
          */}
          <Accordion items={data} keepOthersOpen={true} />
        </div>
        <div className="h-[50vh]">&nbsp;</div>
      </div>
    </>
  );
}

export default App;
