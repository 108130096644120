import { useEffect, useState } from "react";

const Accordion = ({ items, keepOthersOpen }) => {
  const [accordionItems, setAccordionItems] = useState(null);

  useEffect(() => {
    if (items) {
      setAccordionItems([
        ...items.map((item) => ({
          ...item,
          toggled: true,
        })),
      ]);
    }
  }, [items]);

  function scrollItem(id) {
    const item = document.getElementById(id);

    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = item.getBoundingClientRect(),
      offset = elemRect.top - bodyRect.top;

    window.scrollTo({ top: offset - 150 * (id === 0) - 20 * (id !== 0), behavior: "smooth" });
    console.log(item, offset);
  }

  function handleAccordionToggle(clickedItem) {
    setAccordionItems([
      ...accordionItems.map((item) => {
        let toggled = item.toggled;
        if (clickedItem.id === item.id) {
          toggled = !item.toggled;
          if (!toggled) {
            console.log("move?");
            scrollItem(item.id);
          }
        } else if (!keepOthersOpen) {
          toggled = false;
        }
        return {
          ...item,
          toggled,
        };
      }),
    ]);
  }

  return (
    <>
      {accordionItems?.map((listItem, key) => {
        return (
          <div className="border-b border-gray-200" id={key} data-key={key} key={key}>
            <div
              className="flex flex-row text-slate-900 cursor-pointer"
              onClick={() => handleAccordionToggle(listItem, this)}
            >
              <div className="w-full my-1 px-6 py-2">
                <p className="text-[1.5rem]">
                  {listItem.label}
                  <span className="inline-block mr-0">{listItem.toggled ? " " : " "}</span>
                </p>
              </div>
            </div>
            <div className={`w-full my-1 px-6 py-2 ${listItem.toggled ? "hidden" : "visible"}`}>
              <div className="content">{listItem.renderContent()}</div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default Accordion;
